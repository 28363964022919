<template>
  <div>
    <CampusHeader
        :activeTab="0"
        :can-all="true"
        :can-create="canCreate"
        :can-select="true"
        :create-text="createText"
        :header-text="headerText"
        :header_tabs="[{ title: `${tabEntity} List` }]"
        :manage-campuses="true"
        @start-creating="startCreateLeaveRequest"
    ></CampusHeader>
    <section class="section pt-4 box">
      <div class="">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field group-multiline grouped>
              <b-field>
                <b-radio-button v-model="userFilter" native-value="user" @click.native.prevent="handleSelect('user')">
                  <span>User</span>
                </b-radio-button>
                <b-radio-button v-model="userFilter" native-value="approver"
                                @click.native.prevent="handleSelect('approver')">
                  <span>Approver</span>
                </b-radio-button>
                <b-radio-button v-model="userFilter" native-value="approved_by"
                                @click.native.prevent="handleSelect('approved_by')">
                  <span>Approved By</span>
                </b-radio-button>
              </b-field>
              <b-select v-model="yearFilter"
                        expanded
                        placeholder="Year" @input="option=>{if(option===null){$store.state.dates.dateMin=null;$store.state.dates.dateMax=null}}">
                <option :value="null">All</option>
                <option
                    v-for="year of $store.getters['dates/years']"
                    :key="year + 'year'"
                    :value="year"
                >{{ year }}
                </option>
              </b-select>

              <b-datepicker
                  v-model="$store.state.dates.dateMin"
                  :icon="$tc('icons.calendar')"
                  :years-range="[-100,100]"
                  editable
                  expanded
                  placeholder="Start date"
              ></b-datepicker>
              <b-datepicker
                  v-model="$store.state.dates.dateMax"
                  :icon="$tc('icons.calendar')"
                  :years-range="[-100,100]"
                  editable
                  expanded
                  placeholder="End date"
              ></b-datepicker>
              <b-select v-model="typeFilter" expanded
                        placeholder="Request Type">
                <option :value="null">All</option>
                <option v-for="type in $store.state.leave.types" :key="type.id" :value="type.id">{{
                    type.name
                  }}
                </option>
              </b-select>
              <b-select v-model="statusFilter" expanded
                        placeholder="Request Status">
                <option :value="null">All</option>
                <option v-for="status in $store.state.leave.statuses" :key="status.id" :value="status.id">
                  {{ status.name }}
                </option>
              </b-select>
              <p class="control is-flex is-align-items-center">
                <b-checkbox v-model="showArchived" :false-value="false"
                            :true-value="true">Show Archived
                </b-checkbox>
              </p>
              <p class="control">
                <b-button type="is-primary" @click="clear()">
                  Clear
                </b-button>
              </p>
            </b-field>
            <div class="column is-6">
              <b-field expanded group-multiline grouped>
                <b-field v-if="userFilter === 'user'" expanded>
                  <UsersFilter
                      :campus_id="$store.state.campus.all_campuses?null:  parseInt($store.state.campus.selected_campus_id)"
                      :selected_user_id="userId"
                      :type="'autocomplete'"
                      :with_permission="'be staff'"
                      @cleared="userId=null"
                      @selected="option => userId = option.id"></UsersFilter>
                </b-field>
                <b-field v-if="userFilter === 'approver'" expanded>
                  <UsersFilter
                      :campus_id="$store.state.campus.all_campuses?null:  parseInt($store.state.campus.selected_campus_id)"
                      :selected_user_id="approverId"
                      :type="'autocomplete'"
                      :with_permission="'be staff'"
                      @cleared="approverId=null"
                      @selected="option => approverId = option.id"></UsersFilter>
                </b-field>
                <b-field v-if="userFilter === 'approved_by'" expanded>
                  <UsersFilter
                      :campus_id="$store.state.campus.all_campuses?null:  parseInt($store.state.campus.selected_campus_id)"
                      :selected_user_id="approvedById"
                      :type="'autocomplete'"
                      :with_permission="'be staff'"
                      @cleared="approvedById=null"
                      @selected="option => approvedById = option.id"></UsersFilter>
                </b-field>
              </b-field>
            </div>
          </div>
        </div>
        <hr>
        <PerfectScrollbarWrapper>
          <b-table
              :bordered="false"
              :data="leaveRequests"
              :hoverable="true"
              :loading="loadingData"
              :scrollable="false"
              :striped="true"
              class="margin-top is-fullwidth is-clickable"
              @click="viewLeaveRequest"
          >
            <b-table-column
                v-slot="props"
                field="id"
                label="ID"
                numeric
                sortable
                width="40"
            >{{ props.row.id }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="user.first_name"
                label="First Name"
                sortable
            >
              {{ props.row.user.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="user.last_name"
                label="Last Name"
                sortable
            >
              {{ props.row.user.last_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Start Date"
            >
              {{ props.row.dates[0].date }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="End Date"
            >
              {{ props.row.dates[props.row.dates.length - 1].date }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="length"
                label="Days Requested"
                sortable
            >
              {{ props.row.length }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="type_id"
                label="Type"
                sortable
            >
              {{ $store.state.leave.types.find(type => type.id === props.row.type_id).name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="status_id"
                label="Status"
                sortable
            >
              {{ $store.state.leave.statuses.find(status => status.id === props.row.status_id).name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Created At"
            >
              {{ props.row.created_at }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                cell-class="py-1"
                centered
                custom-key="actions"
                label=""
                narrrowed
                width="100"
            >
              <div v-if="canManage" v-on:click.stop>
                <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
                  <template #trigger>
                    <b-icon
                        :icon="$tc('icons.more')"/>
                  </template>
                  <b-dropdown-item aria-role="listitem"
                                   @click="toggleArchive(props.row)">{{ props.row.is_archived ? 'Unarchive' : 'Archive' }}
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem"
                                   @click="approve(props.row)">Approve
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem"
                                   @click="decline(props.row)">Decline
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-table-column>
          </b-table>
        </PerfectScrollbarWrapper>
        <b-pagination
            :current="page"
            :per-page="limit"
            :range-after="2"
            :range-before="2"
            :total="meta.total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="mt-4"
            v-on:change="setPage"
        ></b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import {mapState} from "vuex";
import LeaveRequestForm from "@/components/leave/LeaveRequestForm";
import LeaveRequest from "@/models/LeaveRequest";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import {lastDayOfYear} from 'date-fns'
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "Leave.index",
  components: {CampusHeader, UsersFilter, PerfectScrollbarWrapper},
  data() {
    return {

      search: null,
      loadingFilter: false,
      leaveRequestsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      typeFilter: null,
      statusFilter: null,
      userId: null,
      approverId: null,
      approvedById: null,
      userFilter: null,
      yearFilter: null,
      showArchived: false,
    };
  },
  props: {

    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    }, canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    }, canManage: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Leave Requests";
      },
    },
    createText: {
      type: String,
      default() {
        return "Leave Request";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Leave Request";
      },
    },

  },
  methods: {

    handleSelect(user_type) {
      if (this.userFilter === user_type) {
        this.userFilter = null
        this.userId = null
        this.approvedById = null
        this.approverId = null
      } else {
        this.userFilter = user_type
      }


    },

    clear() {
      this.userFilter = null
      this.userId = null
      this.approvedById = null
      this.approverId = null
      this.statusFilter = null
      this.typeFilter = null
      this.$store.state.dates.dateMin = null
      this.$store.state.dates.dateMax = null
      this.yearFilter = null
      this.showArchived = false
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    approve(leaveRequest) {
      this.loading = true
      let copy = JSON.parse(JSON.stringify(leaveRequest))
      copy.approved_by_user_id = this.$store.state.user.id
      copy.status_id = 2
      LeaveRequest.Update(copy, true).then(() => {
        this.$buefy.snackbar.open(`Leave Request Approved!`);
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    }, decline(leaveRequest) {

      this.loading = true
      let copy = JSON.parse(JSON.stringify(leaveRequest))
      copy.approved_by_user_id = this.$store.state.user.id


      copy.status_id = 3
      LeaveRequest.Update(copy, true).then(() => {
        this.$buefy.snackbar.open(`Leave Request Denied!`);
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    },
    toggleArchive(leaveRequest) {

      this.loading = true
      let copy = JSON.parse(JSON.stringify(leaveRequest))


      copy.is_archived = !copy.is_archived
      LeaveRequest.Update(copy, true).then(() => {
        this.$buefy.snackbar.open(`Leave Request ${copy.is_archived ? 'Archived' : 'Unarchived'}!`);
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    },
    startDelete(leaveRequest) {
      this.$buefy.dialog.confirm({
        title: `Deleting Leave Request`,
        confirmText: `Delete Leave Request`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this leave request?`,
        onConfirm: () =>
            LeaveRequest.Delete(leaveRequest.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Leave Request deleted!`);
                  this.setPage(this.page)
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreateLeaveRequest() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          // default_user: this.user.id,
          canManage: this.canManage,
          canEdit: this.canEdit
          , canCreate: this.canCreate,
          canDelete: this.canDelete,
        },
        component: LeaveRequestForm,
        fullScreen: false,
        trapFocus: true,
      })
    }, viewLeaveRequest(leaveRequest) {
      this.setQuery({leave_request_id: leaveRequest.id})
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: true,
          inModal: true,
          canManage: this.canManage,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
          leaveRequest: leaveRequest
        },
        component: LeaveRequestForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {

          this.removeQuery(['leave_request_id'])
        },

      })
    },
    getFilteredLeaveRequests(text) {
      this.search = text;
    },
    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.leaveRequestsDataHold = this.leaveRequests;
      LeaveRequest.deleteAll();
      this.page = pageNumber;
      LeaveRequest.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['user']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.leaveRequestsDataHold = null;
            if (!initial) {
              this.$cookies.set(
                  `leave_request_filters`,
                  {
                    page: this.page,
                    typeFilter: this.typeFilter,
                    statusFilter: this.statusFilter,
                    userId: this.userId,
                    approverId: this.approverId,
                    approvedById: this.approvedById,
                    userFilter: this.userFilter,
                    showArchived: this.showArchived,
                    year: this.yearFilter,
                    dateMax: this.$store.state.dates.dateMax,
                    dateMin: this.$store.state.dates.dateMin
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {
    yearFilter(newValue) {
      if (newValue !== null) {
        this.$store.state.dates.dateMin = new Date(newValue, 1, 1)
        this.$store.state.dates.dateMax = lastDayOfYear(new Date(newValue, 1, 1))
      }
    },
    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      this.setPage(1);
    },
  },
  computed: {

    ...mapState('campus', ["selected_campus_id", "all_campuses"]),


    filters() {
      return {
        // ...(this.order_direction
        //     ? {
        //       order_direction: this.order_direction,
        //     }
        //     : {}), ...(this.order_by
        //     ? {
        //       order_by: this.order_by,
        //     }
        //     : {}),
        ...(this.typeFilter
            ? {
              type: this.typeFilter,
            }
            : {}),
        ...(this.statusFilter
            ? {
              status: this.statusFilter,
            }
            : {}),
        ...(this.userId
            ? {
              user: this.userId,
            }
            : {}),
        ...(this.approverId
            ? {
              approver: this.approverId,
            }
            : {}),
        ...(this.approvedById
            ? {
              approvedby: this.approvedById,
            }
            : {}),

        ...(this.showArchived
            ? {
              archived: this.showArchived,
            }
            : {}),
        ...(this.$store.state.dates.dateMin
            ? {
              start_date: this.$store.getters['dates/formattedDateMin'],
            }
            : {}),
        ...(this.$store.state.dates.dateMax
            ? {
              end_date: this.$store.getters['dates/formattedDateMax'],
            }
            : {}),
        ...(this.$store.state.dates.dateMax !== null || this.$store.state.dates.dateMin !== null
            ? {
              filter_date: true
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {
              campus: this.$store.state.campus.selected_campus_id,
            }
            : {}),

      };
    },
    leaveRequests() {
      if (this.leaveRequestsDataHold === null) {
        return LeaveRequest.query().with('user').get();
      } else {
        return this.leaveRequestsDataHold;
      }
    },

  },
  mounted() {
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`leave_request_filters`)) {
      let filters = this.$cookies.get(`leave_request_filters`);
      this.page = filters.page
      this.typeFilter = filters.typeFilter
      this.statusFilter = filters.statusFilter
      this.userId = filters.userId
      this.approverId = filters.approverId
      this.approvedById = filters.approvedById
      this.userFilter = filters.userFilter
      this.showArchived = filters.showArchived
      this.year = filters.yearFilter
      if (filters.dateMax !== null) {
        this.$store.state.dates.dateMax = new Date(filters.dateMax)
      }
      if (filters.dateMin !== null) {
        this.$store.state.dates.dateMin = new Date(filters.dateMin)
      }

      this.$cookies.remove(`leave_request_filters`);
    }
    if (this.$route.query.leave_request_id) {
      LeaveRequest.FetchById(this.$route.query.leave_request_id).then(() => {
        this.viewLeaveRequest(LeaveRequest.query().where('id', parseInt(this.$route.query.leave_request_id)).first())
      }).catch(err => {
        this.handleError(err)
      })
    }
    this.$store.dispatch("loader/hide");
    this.setPage(this.page, true);

  },
};
</script>
