<template>
  <LeaveIndex
      :can-edit="!!$store.getters['entities/user-permissions/find']('edit leave-requests')"
      :can-delete="!!$store.getters['entities/user-permissions/find']('delete leave-requests')"
      :can-create="!!$store.getters['entities/user-permissions/find']('create leave-requests')"
      :can-manage="!!$store.getters['entities/user-permissions/find']('manage leave requests')"
  ></LeaveIndex>
</template>
<script>
import LeaveIndex from "@/components/leave/LeaveIndex";

export default {
  name: "Leave.index",
  components: { LeaveIndex },
};
</script>
